import { app_actions } from '../actions/app_actions';
var initialState = {
    ActiveHTTPRequests: 0,
    IsOnline: false,
    percentageData: [],
    snackBarMsg: '',
    versionMismatch: false
};
export function App(state, _a) {
    if (state === void 0) { state = initialState; }
    var type = _a.type, payload = _a.payload;
    switch (type) {
        case 'persist/REHYDRATE': {
            if (payload === null || payload === void 0 ? void 0 : payload.App) {
                payload.App.ActiveHTTPRequests = 0;
                return Object.assign({}, state, payload.App);
            }
            else {
                return Object.assign({}, state);
            }
        }
        case app_actions.Constants.ShowLoader:
            return Object.assign({}, state, { ActiveHTTPRequests: state.ActiveHTTPRequests + 1 });
        case app_actions.Constants.HideLoader:
            return Object.assign({}, state, { ActiveHTTPRequests: state.ActiveHTTPRequests > 1 ? state.ActiveHTTPRequests - 1 : 0 });
        case app_actions.Constants.ResetStore:
            localStorage.removeItem('persist:root');
            localStorage.clear();
            location.reload();
            return Object.assign({}, state, initialState);
        case app_actions.Constants.UPLOAD_PERCENTAGE:
            return Object.assign({}, state, { percentageData: payload });
        case app_actions.Constants.CLEAR_PERCENTAGE_RECORDS:
            return Object.assign({}, state, { percentageData: [] });
        case app_actions.Constants.SNACKBAR_ALERT_MSG:
            return Object.assign({}, state, { snackBarMsg: payload });
        case app_actions.Constants.VersionMisMatch:
            return Object.assign({}, state, { versionMismatch: payload });
        default:
            return state;
    }
}

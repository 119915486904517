import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Typography, Box } from '@mui/material';
import React from 'react';
import { memo } from "react";
import { ErrorIcon, WarningIcon, CheckCircleIcon, CloseFileCardIcon } from "../icons";
import '../../assets/scss/customAlert.scss';
import CachedIcon from '@mui/icons-material/Cached';
export var POPUP_TYPE = {
    UNSAVED_DATA_WARNING: 'UNSAVED_DATA_WARNING',
    DELETE_CONFIRMATION_WARNING: 'DELETE_CONFIRMATION_WARNING',
    SUCCESS_ALERT: 'SUCCESS_ALERT',
    EVENT_NOT_ALLOWED: 'EVENT_NOT_ALLOWED',
    LOGOUT_WARNING: 'LOGOUT_WARNING',
    RELOAD: 'RELOAD'
};
var ConfirmationAndInformationPoup = function (_a) {
    var popupType = _a.popupType, titleText = _a.titleText, message = _a.message, acceptBtnText = _a.acceptBtnText, acceptCallBack = _a.acceptCallBack, denyBtnText = _a.denyBtnText, denyCallBack = _a.denyCallBack, cancelCallBack = _a.cancelCallBack, open = _a.open;
    switch (popupType) {
        case POPUP_TYPE.EVENT_NOT_ALLOWED: {
            return (React.createElement(Dialog, { "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description", open: open, className: 'alert-popwindow' },
                React.createElement(DialogTitle, { id: "alert-dialog-title", className: 'dialogue-title' },
                    React.createElement(Box, { className: 'dialogue-text' },
                        React.createElement(IconButton, { className: 'error-icon' },
                            " ",
                            React.createElement(ErrorIcon, null)),
                        React.createElement(Typography, null, titleText))),
                React.createElement(DialogContent, null,
                    React.createElement(DialogContentText, { id: "alert-dialog-description" }, message)),
                React.createElement(DialogActions, null,
                    React.createElement(Button, { variant: 'contained', size: 'small', className: 'btn-txt', onClick: function () { return acceptCallBack && acceptCallBack(); } }, acceptBtnText))));
        }
        case POPUP_TYPE.SUCCESS_ALERT: {
            return (React.createElement(Dialog, { "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description", open: open, className: 'alert-popwindow' },
                React.createElement(DialogTitle, { id: "alert-dialog-title", className: 'dialogue-title sucess-title' },
                    React.createElement(IconButton, { className: 'success-icon' },
                        " ",
                        React.createElement(CheckCircleIcon, null)),
                    React.createElement(Typography, null, titleText)),
                React.createElement(DialogContent, null,
                    React.createElement(DialogContentText, { id: "alert-dialog-description" }, message)),
                React.createElement(DialogActions, null,
                    React.createElement(Button, { variant: 'contained', size: 'small', className: 'btn-txt', onClick: function () { return acceptCallBack && acceptCallBack(); } }, acceptBtnText))));
        }
        case POPUP_TYPE.DELETE_CONFIRMATION_WARNING: {
            return (React.createElement(Dialog, { "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description", open: open, className: 'alert-popwindow' },
                React.createElement(DialogTitle, { id: "alert-dialog-title", className: 'dialogue-title' },
                    React.createElement(Box, { className: 'dialogue-text' },
                        React.createElement(IconButton, { className: 'error-icon' },
                            " ",
                            React.createElement(ErrorIcon, null)),
                        React.createElement(Typography, null, titleText)),
                    React.createElement(Box, null,
                        React.createElement(IconButton, { className: 'warning-icon', onClick: function () { return cancelCallBack && cancelCallBack(); } },
                            " ",
                            React.createElement(CloseFileCardIcon, null)))),
                React.createElement(DialogContent, null,
                    React.createElement(DialogContentText, { id: "alert-dialog-description" }, message)),
                React.createElement(DialogActions, null,
                    React.createElement(Button, { variant: 'outlined', size: 'small', className: 'btn-txt', onClick: function () { return acceptCallBack && acceptCallBack(); } }, acceptBtnText),
                    React.createElement(Button, { variant: 'contained', size: 'small', className: 'btn-txt', onClick: function () { return denyCallBack && denyCallBack(); } }, denyBtnText))));
        }
        case POPUP_TYPE.UNSAVED_DATA_WARNING:
        case POPUP_TYPE.LOGOUT_WARNING: {
            return (React.createElement(Dialog, { "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description", open: open, className: 'alert-popwindow' },
                React.createElement(DialogTitle, { id: "alert-dialog-title", className: 'dialogue-title' },
                    React.createElement(Box, { className: 'dialogue-text' },
                        React.createElement(IconButton, { className: 'warning-icon' },
                            " ",
                            React.createElement(WarningIcon, null)),
                        React.createElement(Typography, null, titleText)),
                    React.createElement(Box, null,
                        React.createElement(IconButton, { className: 'warning-icon', onClick: function () { return cancelCallBack && cancelCallBack(); } },
                            React.createElement(CloseFileCardIcon, null)))),
                React.createElement(DialogContent, null,
                    React.createElement(DialogContentText, { id: "alert-dialog-description" }, message)),
                React.createElement(DialogActions, null,
                    React.createElement(Button, { variant: 'outlined', size: 'small', className: 'btn-txt btn-small', onClick: function () { return denyCallBack && denyCallBack(); } }, denyBtnText),
                    React.createElement(Button, { variant: 'contained', size: 'small', className: 'btn-txt', onClick: function () { return acceptCallBack && acceptCallBack(); } }, acceptBtnText))));
        }
        case POPUP_TYPE.RELOAD: {
            return (React.createElement(Dialog, { "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description", open: open, className: 'alert-popwindow' },
                React.createElement(DialogTitle, { id: "alert-dialog-title", className: 'dialogue-title sucess-title' },
                    React.createElement(IconButton, { className: 'success-icon' },
                        " ",
                        React.createElement(CachedIcon, null)),
                    React.createElement(Typography, null, titleText)),
                React.createElement(DialogContent, null,
                    React.createElement(DialogContentText, { id: "alert-dialog-description" }, message)),
                React.createElement(DialogActions, null,
                    React.createElement(Button, { variant: 'contained', size: 'small', className: 'btn-txt', onClick: function () { return acceptCallBack && acceptCallBack(); } }, acceptBtnText))));
        }
        default: {
            return React.createElement(React.Fragment, null);
        }
    }
};
export default memo(ConfirmationAndInformationPoup);

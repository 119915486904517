import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { app_actions } from '../actions/app_actions';
import ConfirmationAndInformationPoup, { POPUP_TYPE } from './UtilityComponents/ConfirmationAndInformationPoup';
var BuildVersionMismatch = function () {
    var hasVersionMismatch = useSelector(function (state) { return state.App.versionMismatch; });
    var isLoggedIn = useSelector(function (state) { return state.User.isLoggedIn; });
    var dispatch = useDispatch();
    return (React.createElement(React.Fragment, null,
        React.createElement(ConfirmationAndInformationPoup, { popupType: POPUP_TYPE.RELOAD, titleText: 'Update Required!', message: "A new version of the application is now available! Please refresh the page to apply the latest updates, or sign out and log back in to continue.", acceptBtnText: isLoggedIn ? 'Sign Out' : 'Refresh Now', acceptCallBack: useCallback(function () {
                dispatch(app_actions.setVersionMismatch(false));
                dispatch(app_actions.resetStore());
            }, [hasVersionMismatch]), open: hasVersionMismatch, key: "".concat(hasVersionMismatch) })));
};
export default BuildVersionMismatch;
